export class Config {
	static get() {
		return {
		    apiBackend_prod: "http://localhost:8000/",
		    apiBackend_dev: 'https://api.hiternak.web.id/',
			apiBackend: 'https://identikpkh.com:7061/',
			page:1,
			rows:10
		}
	}
}
